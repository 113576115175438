import ReactHelmet from "components/shared/ReactHelmet/ReactHelmet";
import "./OrderTrack.css";
import React, { useEffect, useRef, useState } from "react";
import Logo from "assets/images/logo-new.webp";
import { useDispatch } from "react-redux";
import { GET_ORDER_TRACKING } from "store/Action/Sidebar/OrderAction";
import { Link, useParams } from "react-router-dom";
import Phone from "assets/images/telephone.png";
import PickUp from "assets/images/pickup.png";
import DropOff from "assets/images/dropoff.png";
import Driver from "assets/images/driver.png";
import CheckBox from "assets/images/checkmark (1).png";
import "ol/ol.css";
import { Map, Overlay, View } from "ol";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import { Feature } from "ol";
import { Point, LineString } from "ol/geom";
import { fromLonLat } from "ol/proj";
import { Icon, Style, Stroke } from "ol/style";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { defaults as defaultControls } from "ol/control";
import NotFound from "components/shared/Loader/NotFound";

const orderStatus = {
  0: "In Progress",
  1: "Delivered",
  2: "Canceled",
  3: "Canceled",
};

export const OrderTrack = () => {
  const dispatch = useDispatch();
  //const params = useParams();
  //const OrderId = params?.id;
  const [orderDetail, setOrderDetails] = useState(null);
  const [isApiLoading, setApiLoading] = useState(true);
  const [togglePhone, setTogglePhone] = useState(false);
  const paths = window.location.pathname.split("/");
  let OrderId = paths[paths.length - 1];
  try {
    OrderId = atob(OrderId);
  } catch {}
  const isMobile = window.innerWidth <= 768;
  useEffect(() => {
    if (OrderId) {
      getOrderDetails("initial");
    }
  }, [OrderId]);

  const getOrderDetails = (type) => {
    console.log("order", window.location.pathname);
    dispatch(
      GET_ORDER_TRACKING(OrderId, (res, loading, error, errorObj) => {
        setApiLoading(loading);
        if (!error) {
          if (res?.data) {
            if (type === "initial") {
              setOrderDetails(res?.data);
            }
          }
        }
      })
    );
  };

  /* Ol */
  const mapRef = useRef(null);
  const pickUpPopupRef = useRef(null);
  useEffect(() => {
    if (!orderDetail) return; // Wait until map data is available

    // Create the OpenLayers map
    const map = new Map({
      target: mapRef.current,
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
      ],
      view: new View({
        center: fromLonLat([
          parseFloat(orderDetail?.driver_lng),
          parseFloat(orderDetail?.driver_lat),
        ]), // Use center from API
        zoom: 13,
      }),
      controls: defaultControls({
        attribution: false, // Ensure attribution control is there
      }).extend([]),
    });

    // Create Start, End, and Driver markers
    const startMarker = new Feature({
      geometry: new Point(
        fromLonLat([
          parseFloat(orderDetail.driver_route[0][1]),
          parseFloat(orderDetail.driver_route[0][0]),
        ])
      ),
      content: `<b>Pickup</b> <br> ${orderDetail?.pickup_address}`,
    });
    const endMarker = new Feature({
      geometry: new Point(
        fromLonLat([
          parseFloat(
            orderDetail.driver_route[orderDetail?.driver_route.length - 1][1]
          ),
          parseFloat(
            orderDetail.driver_route[orderDetail?.driver_route.length - 1][0]
          ),
        ])
      ),
      content: `<b>Dropoff</b> <br> ${orderDetail?.dropoff_address}`,
    });
    const driverMarker = new Feature({
      geometry: new Point(
        fromLonLat([
          parseFloat(orderDetail?.driver_lng),
          parseFloat(orderDetail?.driver_lat),
        ])
      ),
    });

    // Marker Icon Style
    const driverMarkerStyle = new Style({
      image: new Icon({
        src: Driver,
        anchor: [0.5, 1.1],
      }),
    });

    if (orderDetail?.is_order_delivered === "0") {
      [driverMarker].forEach((marker) => marker.setStyle(driverMarkerStyle));
    }

    const startMarkerStyle = new Style({
      image: new Icon({
        src: PickUp,
        anchor: [0.5, 1],
      }),
    });
    startMarker.setStyle(startMarkerStyle);

    const endMarkerStyle = new Style({
      image: new Icon({
        src: DropOff,
        anchor: [0.5, 1],
      }),
    });
    endMarker.setStyle(endMarkerStyle);

    // Add markers to a vector layer
    const markerLayer = new VectorLayer({
      source: new VectorSource({
        features: [startMarker, endMarker, driverMarker],
      }),
    });

    map.addLayer(markerLayer);

    // Add waypoints between start and end
    const waypoints = orderDetail.driver_route.map((route) => {
      return [parseFloat(route[1]), parseFloat(route[0])];
    });

    // Waypoints are split between start-to-driver and driver-to-end
    const driverIndex = waypoints.findIndex(
      (wp) =>
        wp[0] === parseFloat(orderDetail?.driver_lng) &&
        wp[1] === parseFloat(orderDetail?.driver_lat)
    );

    // Get the part of the route up to the driver
    const startToDriverCoords = [
      fromLonLat([
        parseFloat(orderDetail.driver_route[0][1]),
        parseFloat(orderDetail.driver_route[0][0]),
      ]),
      ...waypoints.slice(0, driverIndex + 1).map((wp) => fromLonLat(wp)),
    ];

    // Get the rest of the route from the driver to the end
    const driverToEndCoords = [
      fromLonLat([
        parseFloat(orderDetail?.driver_lng),
        parseFloat(orderDetail?.driver_lat),
      ]),
      ...waypoints.slice(driverIndex + 1).map((wp) => fromLonLat(wp)),
      fromLonLat([
        parseFloat(
          orderDetail.driver_route[orderDetail?.driver_route.length - 1][1]
        ),
        parseFloat(
          orderDetail.driver_route[orderDetail?.driver_route.length - 1][0]
        ),
      ]),
    ];

    const startToDriverLine = new Feature({
      geometry: new LineString(startToDriverCoords),
    });

    const driverToEndLine = new Feature({
      geometry: new LineString(driverToEndCoords),
    });

    // Waypoint line styles (two different colors for two segments)
    startToDriverLine.setStyle(
      new Style({
        stroke: new Stroke({
          color: "#00FF00", // Green for start to driver
          width: 4,
        }),
      })
    );

    driverToEndLine.setStyle(
      new Style({
        stroke: new Stroke({
          color: "#FF0000", // Red for driver to end
          width: 4,
        }),
      })
    );

    // Add the waypoint lines to a vector layer
    const waypointLayer = new VectorLayer({
      source: new VectorSource({
        features: [startToDriverLine, driverToEndLine],
      }),
    });

    map.addLayer(waypointLayer);

    // Create popup overlay
    const pickUpopup = new Overlay({
      element: pickUpPopupRef.current,
      positioning: isMobile ? "bottom-center" : "top-center",
      stopEvent: false,
      offset: [0, isMobile ? -10 : -20],
      autoPan: isMobile,
    });

    map.addOverlay(pickUpopup);

    map.on("pointermove", function (evt) {
      const feature = map.forEachFeatureAtPixel(evt.pixel, (feat) => feat);
      console.log("feature", feature);
      if (feature && feature.get("content")) {
        const coordinate = feature.getGeometry().getCoordinates();
        pickUpopup.setPosition(coordinate);
        pickUpPopupRef.current.innerHTML = feature.get("content");
        pickUpPopupRef.current.style.display = "block";
      } else {
        pickUpPopupRef.current.style.display = "none";
      }
    });

    const updateDriverPosition = () => {
      if (orderDetail?.is_order_delivered === "0") {
        dispatch(
          GET_ORDER_TRACKING(OrderId, (res, loading, error, errorObj) => {
            setApiLoading(loading);
            if (!error) {
              if (res?.data) {
                setOrderDetails(res?.data);
                let currentDriverPos = [
                  parseFloat(res?.data?.driver_lng),
                  parseFloat(res?.data?.driver_lat),
                ];

                // Update the driver's marker position
                driverMarker
                  .getGeometry()
                  ?.setCoordinates(fromLonLat(currentDriverPos));

                // Update the waypoint lines as driver moves
                const newDriverIndex = waypoints.findIndex(
                  (wp) =>
                    wp[0] === currentDriverPos[0] &&
                    wp[1] === currentDriverPos[1]
                );

                startToDriverLine
                  .getGeometry()
                  ?.setCoordinates([
                    fromLonLat([
                      parseFloat(orderDetail.driver_route[0][1]),
                      parseFloat(orderDetail.driver_route[0][0]),
                    ]),
                    ...waypoints
                      .slice(0, newDriverIndex + 1)
                      .map((wp) => fromLonLat(wp)),
                  ]);

                driverToEndLine
                  .getGeometry()
                  ?.setCoordinates([
                    fromLonLat(currentDriverPos),
                    ...waypoints
                      .slice(newDriverIndex + 1)
                      .map((wp) => fromLonLat(wp)),
                    fromLonLat([
                      parseFloat(
                        orderDetail.driver_route[
                          orderDetail?.driver_route.length - 1
                        ][1]
                      ),
                      parseFloat(
                        orderDetail.driver_route[
                          orderDetail?.driver_route.length - 1
                        ][0]
                      ),
                    ]),
                  ]);
              }
            }
          })
        );
      }
    };

    const intervalId = setInterval(updateDriverPosition, 10000); // 10-second update interval

    // Cleanup function to stop the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
      map.setTarget(undefined); // Unmount the map
    };
  }, [orderDetail]); // Re-run when API data is fetched

  useEffect(() => {
    const tawk = document.createElement("script");
    tawk.async = true;
    tawk.src = "https://embed.tawk.to/54fa63c38ac92cc0041d1a42/default";
    tawk.charset = "UTF-8";
    tawk.setAttribute("crossorigin", "*");

    document.body.appendChild(tawk);

    tawk.onload = () => {
      if (window.Tawk_API) {
        console.log("Loaded2");
        window.Tawk_API.showWidget();
      }
    };
    /* return () => {
      document.body.removeChild(tawk);
    }; */
  }, [orderDetail]);

  return (
    <div className="main-container mt-0">
      <ReactHelmet />
      <section className="order-detail-sec">
        {!isApiLoading && !orderDetail && (
          <div className="row">
            <NotFound height={250} width={250} />
          </div>
        )}
        {orderDetail && (
          <div className="row order p-0 m-0">
            <div className="col-md-4 order-detail-left">
              <div className="order-head">
                <img src={Logo} style={{ width: "150px" }} alt="" />
              </div>
              <div className="oder-contant">
                <p>{orderDetail?.delivery_time_message}</p>
                <p
                  className={`badge-one ${
                    orderDetail?.is_order_delivered === "2" ||
                    orderDetail?.is_order_delivered === "3"
                      ? "red-badge"
                      : ""
                  }`}
                >
                  {orderDetail?.is_order_delivered === "1" && (
                    <img src={CheckBox} alt="" />
                  )}
                  {orderStatus[orderDetail?.is_order_delivered]}
                </p>
                <div className="borders"></div>
                <div className="order-address">
                  <div className="border-bottom">
                    <p className="order-lebal">Delivery ID</p>
                    <p className="order-text">{OrderId}</p>
                  </div>
                  <div className="border-bottom mt-3  mb-3 pb-3 position-relative">
                    <div className="d-flex address-box">
                      <div className="address-icon">
                        <img src={PickUp} alt="" />
                      </div>
                      <div>
                        <p
                          className="order-lebal"
                          style={{ fontWeight: "500", marginBottom: "0px" }}
                        >
                          Pickup Address
                        </p>
                        <p className="order-text">
                          {orderDetail?.pickup_address}
                        </p>
                      </div>
                    </div>
                    <div className="d-flex address-box">
                      <div className="address-icon">
                        <img src={DropOff} alt="" style={{ width: "38px" }} />
                      </div>
                      <div>
                        <p
                          className="order-lebal"
                          style={{ fontWeight: "500", marginBottom: "0px" }}
                        >
                          Dropoff Address
                        </p>
                        <p className="order-text">
                          {orderDetail?.dropoff_address}
                        </p>
                      </div>
                    </div>
                    <div className="dot-line"></div>
                  </div>
                  <div className="border-bottom d-flex position-relative pb-3">
                    <div>
                      <p
                        className="order-lebal"
                        style={{ fontWeight: "500", marginBottom: "0px" }}
                      >
                        Driver
                      </p>
                      <p className="order-text" style={{ marginBottom: "0px" }}>
                        {orderDetail?.driver_name ?? ""}
                      </p>
                      <p
                        className="order-lebal"
                        style={{ fontWeight: "500", marginBottom: "0px" }}
                      >
                        {orderDetail?.vehicle ?? ""}
                      </p>
                    </div>
                    <div style={{ position: "absolute", right: "0" }}>
                      <img
                        onClick={() => {
                          setTogglePhone(!togglePhone);
                        }}
                        src={Phone}
                        alt=""
                        style={{
                          cursor: "pointer",
                          width: "52px",
                          background: "#f7f5ff",
                          padding: "10px",
                          borderRadius: "14px",
                          marginRight: "4px",
                        }}
                      />
                      {togglePhone && (
                        <Link
                          to={`tel:${orderDetail?.driver_phone}`}
                          className="order-text"
                        >
                          {orderDetail?.driver_phone ?? ""}
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8 order-detail-right">
              <div
                ref={mapRef}
                className="map-box"
                style={{ width: "100%", height: "100vh" }}
              />
              <div
                ref={pickUpPopupRef}
                className="ol-popup"
                style={{
                  width: "200px",
                  height: "90px",
                  backgroundColor: "white",
                  padding: "10px",
                  borderRadius: "5px",
                  overflow: "auto",
                  border: "1px solid #ccc",
                  display: "block",
                }}
              >
                <div>Marker content</div> Customize popup content
              </div>
            </div>
          </div>
        )}
      </section>
    </div>
  );
};
